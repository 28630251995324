
import { defineComponent } from "vue";
import Dropdown2 from "@/components/dropdown/Dropdown2.vue";

export default defineComponent({
	name: "ProjectSpendingTable",
	components: {
		Dropdown2,
	},
	props: {
		widgetClasses: String,
	},
	setup() {
		const list = [
			{
				user: {
					avatar: "/media/avatars/150-1.jpg",
					name: "Emma Smith",
					email: "e.smith@kpmg.com.au",
				},
				price: "Aug 19, 2021",
				amount: "$737.00",
				technologies: "Laravel, Metronic",
				status: {
					label: "Approved",
					color: "success",
				},
			},
			{
				user: {
					state: "danger",
					name: "Melody Macy",
					email: "melody@altbox.com",
				},
				price: "Feb 21, 2021",
				amount: "$470.00",
				technologies: "ReactJs, HTML",
				status: {
					label: "In Progress",
					color: "warning",
				},
			},
			{
				user: {
					avatar: "/media/avatars/150-2.jpg",
					name: "Max Smith",
					email: "max@kt.com",
				},
				price: "Apr 15, 2021",
				amount: "$511.00",
				technologies: "Python, MySQL",
				status: {
					label: "Success",
					color: "danger",
				},
			},
			{
				user: {
					avatar: "/media/avatars/150-4.jpg",
					name: "Sean Bean",
					email: "sean@dellito.com",
				},
				price: "Mar 10, 2021",
				amount: "$434.00",
				technologies: "ReactJS, Ruby",
				status: {
					label: "Rejected",
					color: "info",
				},
			},
			{
				user: {
					avatar: "/media/avatars/150-15.jpg",
					name: "Brian Cox",
					email: "brian@exchange.com",
				},
				price: "Apr 15, 2021",
				amount: "$664.00",
				technologies: "AngularJS, C#",
				status: {
					label: "In Progress",
					color: "primary",
				},
			},
		];

		return {
			list,
		};
	},
});
